.new-blog-container {
  display: flex;
  flex-direction: column;
  margin-top: 100px;
}

.new-blog-content {
  height: 400px;
  margin-bottom: 80px;
}
