.blog-details-cover {
  width: 100%;
  border-radius: 10px;
  margin-bottom: 50px;
  max-height: 80vh;
  object-fit: cover;
  object-position: 0% 0%;
  margin-top: 50px;
}

.blog-details-root {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 100px;
}

.blog-details-title {
  margin-top: 50px;
  margin-bottom: 50px;
  font-weight: bold;
}

.blog-details-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.blog-details-author {
  width: 300px;
}

.blog-details-info {
  display: flex;
  flex-direction: column;
  align-items: center;
}
